<template>
  <div>
    <el-select
      v-model="selectedAddress"
      :style="`width: calc(100% - ${addButtonVisibility ? '40px' : '20px'})`"
      ref="field"
      filterable
      clearable
      remote
      value-key="key"
      :size="size"
      :loading="loading"
      :remote-method="search"
      :placeholder="placeholder"
      @focus="setDefaultAddress"
      @clear="clearSelectedAddress"
    >
      <i slot="prefix" class="el-icon-search" style="margin-left: 5px; font-weight: bold"></i>
      <el-option
        v-for="item in searchOptions"
        :key="item.key"
        :label="getAddressLabel(item)"
        :value="item"
        class="el-option-content">
        <div class="el-option-text" @click="changeSelected(item); addAddress(item)">
          {{ getAddressLabel(item) }}
        </div>
        <div class="el-option-buttons">
          <el-button type="text" size="small" class="el-option-button" @click="changeSelected(item, true)">
            <i class="el-icon-setting el-icon--left"></i>
            {{ $locale.address_service.button.specify }}
          </el-button>
        </div>
      </el-option>
    </el-select>
    <i
      class="el-icon-plus"
      style="margin-left: 5px; font-weight: bold; cursor:pointer; color: #409EFF;"
      v-show="addButtonVisibility"
      @click="addAddress(selectedAddress)"></i>
    <i
      :class="{'el-icon-arrow-down': !showAddressObjects, 'el-icon-arrow-up': showAddressObjects}"
      style="margin-left: 5px; font-weight: bold; cursor:pointer;"
      @click="showAddressObjects = !showAddressObjects"
    ></i>
    <address-objects
      :is-active.sync="showAddressObjects"
      :dropdown-width="dropdownWidth"
      :level="level"
      :type="type"
      :required-level="requiredLevel"
      :selected-addresses="selectedAddress"
      @set-address="setSelectedAddress"
      style="margin-top: 5px"
    ></address-objects>
    <div
      v-for="(item, index) in selectedAddresses"
      :key="item.key"
      style="width: calc(100% - 20px)"
    >
      <slot name="address_label" :deleteAddress="deleteAddress" :index="index" :item="item" :getAddressLabel="getAddressLabel">
        <i
          :class="'el-icon-close'"
          style="font-size: 1.5em; vertical-align: sub; margin-left: 5px; cursor:pointer;"
          @click="deleteAddress(index)"
        ></i>
        <span style="margin-left: 5px;">{{ getAddressLabel(item) }}</span>
      </slot>
    </div>
  </div>
</template>

<script>

  import AddressObjects from './AddressObjects'

  export default {
    name: 'gar_address_multi_field',
    components: { AddressObjects },
    props: {
      size: {
        type: String
      },
      level: {
        type: Number
      },
      placeholder: {
        type: String
      },
      searchLimit: {
        type: Number,
        default: 50
      },
      value: {
        type: Array
      },
      requiredLevel: {
        type: String
      },
      defaultAddress: {
        type: String
      },
      initialDropdownWidth: {
        type: Number
      },
      type: {
        type: String,
        default: 'administrative'
      }
    },
    data () {
      return {
        selectedAddresses: this.value || [],
        selectedAddress: {},
        searchOptions: [],
        showAddressObjects: false,
        dropdownWidth: 100,
        loading: false
      }
    },
    computed: {
      addButtonVisibility () {
        // return false
        return Object.keys(this.selectedAddress || {}).length !== 0
      }
    },

    watch: {
      value: {
        handler (newValue) {
          this.selectedAddresses = newValue;
          (newValue || []).forEach((item) => {
            const found = this.searchOptions.find((_) => _.key === item.key)
            if (found) {
              found.id = item.id
              found.address_id = item.id
            }
          })
        },
        deep: true
      },
      showAddressObjects (value) {
        if (value) {
          this.updateDropdownWidth()
        }
      }
    },

    mounted () {
      this.updateDropdownWidth()
    },

    methods: {
      formatAddress (address) {
        if (Object.keys(address || {}).length === 0) {
          return null
        }
        return {
          id: address.address_id || null,
          level: address.level || this.level,
          region_code: address.region_code,
          objectid: address.objectid,
          type: this.type,
          house: (this.level === 10 && (this.selectedAddress.house && this.selectedAddress.house.name)) ? this.selectedAddress.house.name : null,
          room: address.flat || null,
          description: address.description || null
        }
      },
      deleteAddress (addressIndex) {
        this.selectedAddresses.splice(addressIndex, 1)

        this.$emit('input', this.selectedAddresses)
      },
      addAddress (address) {
        if (Object.keys(address || {}).length === 0) {
          return
        }
        if (this.selectedAddresses.find(item => item.key === address.key)) {
          return
        }

        address.type = this.type
        address.house = (this.type === 10 && (this.selectedAddress.house && this.selectedAddress.house.name)) ? this.selectedAddress.house.name : null
        address.room = address.flat || null
        if (address.id) {
          let oldAddressIndex = this.selectedAddresses.findIndex(addressTemp => addressTemp.id === address.id)
          if (oldAddressIndex !== -1) {
            // Change exists address
            this.selectedAddresses[oldAddressIndex] = address
          } else {
            // Add not finded
            this.selectedAddresses.push(address)
          }
        } else {
          // Add new
          this.selectedAddresses.push(address)
        }

        this.$emit('input', this.selectedAddresses)

        this.clearSelectedAddress()
      },
      updateDropdownWidth () {
        this.dropdownWidth = this.initialDropdownWidth || (this.$el.offsetWidth > 500 ? 500 : this.$el.offsetWidth - 20)
      },
      setDefaultAddress () {
        if (this.defaultAddress) {
          const inputEl = this.$refs.field.$el.children[0].children[0]
          inputEl.value = this.defaultAddress
          this.search(this.defaultAddress)
        }
      },
      // проставить адрес из заполненной формы
      async setSelectedAddress (address) {
        if (Object.keys(address || {}).length === 0) {
          this.clearSelectedAddress()
          return
        }
        let option = await this.loadAddress(address) || {}
        if (Object.keys(option).length > 0) {
          this.selectedAddress = option
        } else {
          this.selectedAddress = address
        }

        this.addAddress(this.selectedAddress)
      },
      // загружаем адрес в селект
      async loadAddress (address) {
        if (Object.keys(address.data || {}).length === 0 || !address.type) {
          return
        }
        let url = null
        if (address.type === 'house') {
          if (address.data.id) {
            url = `${this.$config.api}/garaddressservice/addresses/houses/${address.data.id}/${this.type}`
          } else {
            //ввели руками
            url = `${this.$config.api}/garaddressservice/addresses/address_objects/${address.data.previous}/${this.type}`
          }
        } else {
          url = `${this.$config.api}/garaddressservice/addresses/address_objects/${address.data.id}/${this.type}`
        }
        if (!url) {
          return false
        }
        const fiasData = await this.$http.get(url)
        const fiasAddress = fiasData.data
        if (!fiasAddress) {
          console.warn('address not found')
          return false
        }
        let option = fiasAddress
        /* Other fields */
        this.$set(option, 'flat', address.flat)
        // По умолчанию null, на сервере проверка дублей по NULL
        this.$set(option, 'description', address.description || null)
        if (address.type === 'house' && !address.data.id) {
          this.$set(option, 'house', { name: address.data.name })
        }

        // Для привязки существующего адреса по id (не address_id). Новый адрес если заполнен description.
        // - Может быть заполнен из-за выбора flat (см. address_id_flat в save в AddressObject.vue)
        if (address.id) {
          // ID на входе в функцию loadAddress есть, привязать существующий адрес по ID
          this.$set(option, 'id', address.id)
        } else if (option.address_id && !address.flat && !address.description) {
          // Привязать к существующему адресу только при не заполненом flat и description
          this.$set(option, 'id', option.address_id)
        }
        // address_id от сервера привязанный к house, приравниваем к id, чтобы не сбивало с толку при отладке
        this.$set(option, 'address_id', option.id)

        //this.$set(option, 'level', address.level)
        this.$set(option, 'level', option.flat ? 11 : address.level)

        this.$set(option, 'key', this.createKey(fiasAddress))
        //this.$set(option, 'key', `${option.objectguid}_${option.house}_${option.flat}_${option.description}`)
        //option = Object.assign(option, address.addressObjectsValue)

        if (!this.searchOptions.find(item => item.key === option.key)) {
          // Адрес с ключем не найден, добавить адрес в список поиска
          this.searchOptions.push(option)
        }

        return option
      },
      createKey(address) {
        let keyArray = address.object_ids ? [...Object.values(JSON.parse(address.object_ids.replace('\"', '"')))] : address.guid
        //address.house ? keyArray.push(address.house) : null
        address.flat ? (address.flat.name ? keyArray.push(address.flat.name) : keyArray.push(address.flat)) : null
        return keyArray.join('_')
      },
      async search (query) {
        if (!query) {
          this.searchOptions = []
          return false
        }
        this.loading = true
        query = encodeURIComponent(query)
        const fiasData = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/find/${this.level}/${this.type}/${query}?limit=${this.searchLimit}`)
        fiasData.data.forEach(fiasAddress => {
          this.searchOptions.push({ ...fiasAddress, key: this.createKey(fiasAddress) })
        }, this)
        this.loading = false
      },
      async getAdditionalAddressProperties(objectIdsJSON) {
        let result = {}
        const objectIds = JSON.parse(objectIdsJSON.replace('\"', '"'))
        const keys = Object.keys(objectIds)
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const value = objectIds[key]
          if (key === 'houses') {
            const addressItemData = await this.$http.get(`${this.$config.api}/garaddressservice/houses/${value}`)
            result[key] = addressItemData.data
          } else if (['flat', 'description'].includes(key)) {
            result[key] = value
          } else {
            const addressItemData = await this.$http.get(`${this.$config.api}/garaddressservice/address_objects/${value}`)
            result[key] = addressItemData.data
          }
        }
        return result
      },
      // выбор адреса из выпадающего списка
      async changeSelected (address, isShowAddressObjects = false) {
        if (!address) {
          return
        }
        //const realAddress = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/${address.objectid}`)
        // address_id от сервера привязанный к house, приравниваем к id
        this.$set(address, 'id', address.address_id)
        /* Other fields */
        // По умолчанию null, на сервере проверка дублей по NULL
        this.$set(address, 'description', address.description || null)
        //this.$set(this, 'selectedAddress', address)
        this.selectedAddress = address

        if (isShowAddressObjects) {
          // Показать модальное окно адреса
          this.showAddressObjects = true
        }
      },
      clearSelectedAddress () {
        this.selectedAddress = null
        setTimeout(() => { // Без задержки не стирает по кнопке "Уточнить" (specify) в option. Vue.js не сразу проставляет значение внутри фреймворка
          this.selectedAddress = {}
        })
        //this.$set(this, 'selectedAddress', null)
      },
      getAddressLabel (address) {
        let addressLabel = address.name ? address.name : address.address // Может содержать flat
        if (this.level >= 10 && address.house && !address.id) {
          addressLabel += ', ' + address.house.name
        }

        if (address.flat && !addressLabel.includes(', кв.') /* Проверить содержание flat */) {
          addressLabel += ', кв. ' + address.flat
        }

        if (address.description) {
          addressLabel += ', ' + address.description
        }

        return addressLabel
      }
    }
  }
</script>

<style scoped>

</style>
