<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>
      <gar-address-multi-field
        :level="level"
        :placeholder="placeholder"
        :value="addresses"
        :required-level="requiredLevel"
        :default-address="defaultAddress"
        :type="type"
        @input="$emit('input', $event)"
      ></gar-address-multi-field>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
  import mixin from '../mixins'
  import VisibleMixin from '../visible_properties_mixin'
  import registryMixin from './registry_mixins'
  import InputLabel from '@/mixins/inputLabel.js'

  import GarAddressMultiField from '@/core/infrastructure/components/GarAddressMultiField'

  export default {
    name: 'gar_address_multi_field',
    mixins: [mixin, VisibleMixin, registryMixin, InputLabel],
    components: { GarAddressMultiField },
    props: {
      registry_properties: {
        type: Array,
        frozen: true
      },
      label: {
        type: String,
        description: 'Название'
      },
      placeholder: {
        type: String,
        description: 'Плейсхолдер'
      },
      tooltip: {
        type: String,
        description: 'Подсказка'
      },
      defaultAddress: {
        type: String,
        description: 'Адрес по умолчанию'
      },
      requiredLevel: {
        type: String,
        description: 'Обязательная часть адреса',
        editor: 'Select',
        options: {
          options: [
            { id: 'subject', name: 'Регион' },
            { id: 'area', name: 'Район' },
            { id: 'city', name: 'Город' },
            { id: 'locality', name: 'Населенный пункт' },
            { id: 'planning_structure', name: 'Планировочная структура' },
            { id: 'street', name: 'Улица' },
            { id: 'house', name: 'Дом' },
            { id: 'flat', name: 'Квартира/Помещение' },
            { id: 'description', name: 'Описание' }
          ]
        }
      }
    },
    data () {
      return {
        addresses: []
      }
    },
    computed: {
      type () {
        return (this.registry_properties.find(item => item.id === 'address_type') || {}).value
      },
      level () {
        return (this.registry_properties.find(item => item.id === 'object_level') || {}).value
      },
      computedStyle () {
        let css = this.CSS
        if (this.align) {
          css += ';text-align:' + this.align
        }
        if (this.margin) {
          css += ';margin:' + this.margin
        }
        if (this.width && !this.block) {
          css += ';width:' + this.width
        }
        if (!this.block) {
          css += `;display: inline-block; width:${this.width || '200px'}`
        }
        if (this.wrapper) {
          css += ';display: block;'
        }

        return css
      }
    },
    async mounted () {
      await this.parseValue()
    },
    methods: {
      async parseValue () {
        if (!this.value) {
          return false
        }
        let parsed = this.value
        try {
          parsed = JSON.parse(this.value)
        } catch (e) {
        }
        let addressesPromises = []
        if (Array.isArray(parsed)) {
          for (const address of parsed) {
            if (typeof parsed === 'object') {
              addressesPromises.push(this.getAddressById(address.id))
            } else {
              addressesPromises.push(this.getAddressById(address))
            }
          }
        } else {
          if (typeof parsed === 'object') {
            addressesPromises.push(this.getAddressById(parsed.id))
          } else {
            addressesPromises.push(this.getAddressById(parsed))
          }
        }

        this.addresses = await Promise.all(addressesPromises)
        this.$emit('input', this.addresses)
      },
      async getAddressById (addressId) {
        if (!addressId) {
          return {}
        }

        let data = await this.$http.get(`${this.$config.api}/garaddressservice/addresses?id=${addressId}`)
        return data.data ? Object.assign(data.data[0], {
          name: data.data[0].addresses,
          address_id: data.data[0].id
        }) : {}
      }
    },
    watch: {
      value: {
        async handler (newValue) {
          // Обработать ответ от сервера при сохранении
          if (typeof newValue === 'string') {
            // Ответ от БД приходит string, не массивом
            // Обработать ответ из БД для присвоения address_id (id), после добавления новых адресов (без id)
            await this.parseValue()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
